import React from "react";
import "./styles.css";

const Footer = () => {
  return (
    // To delete the hosted File https://postimg.cc/6T0pGDk4/2d31cf5c
    <img
      src="https://i.postimg.cc/Gh086RzK/Screenshot-2023-12-25-at-1-17-49-PM.png"
      alt="footer"
      className="footer"
    />
  );
};

export default Footer;
